import axios, { AxiosResponse, AxiosError } from "axios";

export const sendRequest = async (
  method: string,
  url: string,
  data?: object | null,
  onSuccess?: (res: AxiosResponse) => void,
  onError?: (err: AxiosError) => void,
  onFinally?: () => void,
) => {
  const token = localStorage.getItem("Access-Token");

  const headers: { [key: string]: string } = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  await axios({
    method: method,
    url: url,
    data: data,
    headers: headers,
  })
    .then((res: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((err: AxiosError) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("Access-Token");
        window.location.href = "/login";
      }
      if (onError) {
        onError(err);
      }
    })
    .finally(() => {
      if (onFinally) {
        onFinally();
      }
    });
};
