import React, { useEffect, useState } from "react";
import { Modal } from "../../components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toggleModal, setData } from "../../store/actionCreators";
import sendRequest from "../../services";
import { AxiosResponse } from "axios";
import { HistoryDataItem, HistoryTableRowProps } from "../../type";

export const HistoryModal: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const dataSelector = useSelector((state: any) => state.data);
  const sharingSlug = dataSelector.shared_slug;

  const [historyDataState, setHistoryData] = useState<HistoryDataItem[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    dispatch(toggleModal("history"));
  };

  const handleLoadClaimed = async (res: AxiosResponse) => {
    try {
      const data = res.data as HistoryDataItem[];
      setHistoryData(data);
      setError(null);
    } catch (error) {
      console.error('Error setting data:', error);
      setError("Error setting history data");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequest(
          "get",
          `/api/v1/main/history/${sharingSlug}`,
          undefined,
          handleLoadClaimed,
          undefined,
          undefined,
        );
        setError(null);
      } catch (error) {
        setError("Error fetching history data");
      }
    };

    if (window.location.pathname === `/compensation/${sharingSlug}`) {
      fetchData();
    }
  }, [sharingSlug]);

  const HistoryTableRow: React.FC<HistoryTableRowProps> = ({ data }) => (
    <tr>
      <td>{data.claiming_code}</td>
      <td>{data.timestamp_claiming}</td>
      <td>{data.claimed_squaremeters}</td>
      <td>{data.region}</td>
    </tr>
  );

  return (
    <Modal
      type="history"
      title={t("CompensationHistory")}
      helper={t("CompensationHelper")}
      classname="history"
      handleClose={handleClose}
    >
      <div className="history">
        <table className="history-table">
          <thead className="history-table-column">
            <tr>
              <th>{t("RedeemedCodeHistory")}</th>
              <th>{t("RedeemedTimestampHistory")}</th>
              <th>{t("RedeemedSqaremetersHistory")}</th>
              <th>{t("RedeemedRegionHistory")}</th>
            </tr>
          </thead>
          <tbody className="history-table-input">
            {error ? (
              <tr>
                <td colSpan={4}>{error}</td>
              </tr>
            ) : (
              historyDataState.map((data) => (
                <HistoryTableRow key={data.claiming_code} data={data} />
              ))
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};