import React from "react";

import { useDispatch } from "react-redux";
import { toggleModal } from "../../store/actionCreators";

import { Modal } from "../../components";

import { useTranslation } from "react-i18next";

export const ErrorModal = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(toggleModal("error"));
  };

  return (
    <Modal
      type="error"
      title={t("oops")}
      helper={t("codeUnknown")}
      handleClose={handleClose}
      classname="error"
    >
      <div dangerouslySetInnerHTML={{ __html: t("ContributeWhenCodeError") }} />
    </Modal>
  );
};
