import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      welcome: 
        "Welcome",
      want:
        "Access your Compensation here!",
      enter:
        "Either redeem your personal FlowerMe-ID or access your compensation account. You can optionally link your Email address and start collecting contributions",
      flowermeContributionCode:
        "FlowerMe contribution code",
      enterEmail:
        "Enter Email",
      acceptPrivacyPolicy:
        "Accept <1>privacy policy</1>",
      linkEmail:
        "I want to link my email address to my tracking code",
      accessCompensation:
        "You want to access your compensation through your linked account?",
      accessCompensationLink:
        "Click here",
      redeemFlowerMe:
        "Redeem FlowerMe contribution code",
      newToFlowerMe:
        "Are you new to the FlowerMe initiative?",
      getInTouch:
        "Get in touch with us to compensate for your purchases today!",
      surname:
        "Surname",
      lastname:
        "Last name",
      email:
        "Email",
      newsletterAgreement:
        "I agree to receive the newsletter in compliance with the <1>Privacy Policy</1>.",
      accessYourCompensation:
        "Access your compensation",
      accessViaCode:
        "You want to access your compensation through a FlowerMe contribution code? click here",
      oops:
        "Oops!",
      codeUnknown:
        "Your FlowerMe contribution code is unknown, please check your spelling. Are you looking to contribute?",
      successRedeem:
        "Success! You can view your history in a few seconds.",
      currentContribution:
        "Your current contribution to the FlowerMe Nature Vault",
      storedCO2:
        "Stored CO₂",
      absorbedCO2:
        "Absorbed CO₂",
      lastCompensation:
        "Last compensation tracked",
      patron:
        "Patron",
      protector:
        "Protector",
      protectedRainforest:
        "Currently protected m² in FlowerMe's ecosystems",
      supportProjects:
        "With your contribution, FlowerMe supports regenerative projects around the world!",
      claimContribution:
        "You want to claim your contribution?",
      enterEmailForClaim:
        "Enter your email address",
      claim:
        "Claim",
      successEmailClaim:
        "Success! The FlowerMe contribution code linking to your email address was successful!",
      storedCO2Tooltip:
        "Stored CO₂ refers to the carbon that is captured by a party and is permanently stored in a carbon reservoir to prevent its release into the atmosphere. A typical carbon capturer is the rainforest or peatland rich in CO2.",
      absorbedCO2Tooltip:
        "Absorbed CO₂ refers to carbon dioxide that is absorbed by natural entities and removed from the atmosphere in that process. This CO₂ is then incorporated into plants or biomass.",
      rainforestTooltip:
        "The shown square meters rainforest indicate the rainforest you are currently protecting, which is proofed by an NFT from our regenerative partner project. If you want to know more about it, you can access the rainforest-data <1>here</1>.",
      natureVaultTooltip:
        "The FlowerMe nature vault is a collection for regenerative and conservation projects supporting nature and environment. Corresponding financial support is certified by NFTs.",
      cookieBanner:
        "We use cookies to provide the usage of this application, to generate a proactive support experience and to analyze the usage of the website. By continuing to use this application as a subpage of our website, you agree to have read this notice and accept the <1>Privacy Policy</1> and the use of cookies.",
      cookieAgree:
        "I agree",
      loginVerificationModal:
        "You already have an account? <1>Click here</1>",
      sendEmailAddress:
        "Send",
      becomeFlowerMePartner:
        "Become a partner",
      SocialShareText:
        "Make every flower count! I just compensated my flowers with #FlowerMe, the easy and transparent way to offset supply chain #co2-emissions, support #sustainability, and protect our planet with every purchase. Check out my contribution here:",
      socialHeader:
        "FlowerMe offsets flower emissions!",
      getImprintFooter:
        "Imprint",
      getImprintFooterLink:
        "https://flowerme.xyz/imprint/",
      getPrivacyPolicyFooter: 
        "Privacy Policy",
      getPrivacyPolicyFooterLink:
        "https://flowerme.xyz/privacy-policy/",
      getTAndSFooter:
        "Terms & Services",
      getTAndSFooterLink:
        "https://flowerme.xyz/terms-and-services/",
      getLinkFlowerMeHome:
        "https://flowerme.xyz/",
      getLinkInitiativePlus:
        "https://flowerme.xyz/initiative-plus/",
      getLinkFlowerMeShop:
        "https://shop.flowerme.xyz/",
      getLinkFlowerMeAbout:
        "https://flowerme.xyz/about/",
      getLinkBecomingPartner:
        "https://shop.flowerme.xyz/product-category/compensate-for-business/",
      CookieConsensButton:
        "Remember Me",
      howItWorksHeader:
        "This is how FlowerMe Compensate works",
      quantifyExplainer:
        "<strong>1. Quantify:</strong> Determine the emissions of your flowers with our Quantify-Tool",
      shopRedirectExplainer:
        "<strong>2. Compare:</strong> Choose from the proposed selection of compensation-packages",
      compensateYourCode:
        "<strong>3. Compensate:</strong> Enter your personal FlowerMe-ID online and track your compensation",
      shareYourImpact:
        "<strong>4. Share:</strong> Present your customers with real sustainability and tell the world",
      SuccessMailchimp:
        "Your contact submission was successful!",
      ContributeWhenCodeError:
        "Are you willing to <a href='https://shop.flowerme.xyz/store/'>contribute</a>?",
      CompensationAccountAccess:
        "Do you want to",
      CompensationAccountAccess2:
        "access your compensation account?",
      orTranslation:
        "or",
      AcceessShopDoubleReedem:
        "Do you want to <a href='https://shop.flowerme.xyz/store/'> contribute and open your own account? </a>",
      CompensationHistory:
        "Your compensations at a glance:",
      CompensationHelper:
        "Get a list of all compensations tracked until today. The date represents the date of your redemption.",
      RedeemedCodeHistory:
        "Your redeemed Code",
      RedeemedTimestampHistory:
        "Date of redemption",
      RedeemedSqaremetersHistory:
        "Protected Squaremeters",
      RedeemedRegionHistory:
        "Protected Ecosystem",
      accessHistoryModal:
        "Click here to access you contribution history!",
      PatronsProfile:
        "Your Compensation Profile",
      logoutButton:
        "Leave Profile",
      ChangeName:
        "Change your patron's name!",
      changeNameHelper:
        "You can enter your desired name here, which is also publicly visible:",
      changeDashboardInfo:
        "Update your compensation profile",
      provideName:
        "Patron's name:",
      sendName:
        "Change name",
      RememberMe:
        "Remember Me",
      projectInfoEstonia:
        "Get to know our afforestion project in Estonia",
      estonianProjectOverview:
        "Afforestation in Estonia: Plant a tree with Green Deal",
      estoniaInfo:
        "Planting does not happen overnight. It requires thorough preparatory work. Our partner GreenDeal carefully selects suitable planting sites and manages them. We want to provide you with all the necessary information about the project here at all times. So check back regularly for the latest updates!",
      checkForNewClaim:
        "Check",
      EstoniaOwnOverview:
        "Here is an overview about your current project contribution:",
      NumberTreesOverview:
        "Number of Trees:",
      LastPlantingDate:
        "Last date of purchase:",
      PatronsNameEstonia:
        "Trees are planted by:",
      estonianAchivable:
        "Our innovative strategy in the fight against climate change revolves around sustainable forestry and enhanced forest management, emphasizing carbon sequestration. The Carbon Reserve, comprised of multiple Carbon Parks, operates under the Forest Management Methodology, a collaborative research effort with leading universities. Our commitment is to plant all forests within 18 months of the project deadline (Spring 2025), ensuring meticulous land acquisition, soil analysis, tree selection, mineralization, and detailed planning for optimal impact. A dedicated team manages each forest, following a scientifically-developed methodology, with all Carbon Parks PEFC certified for expected management quality.",
      PlantingDate:
        "Information about our project partner",
      FirstNameAnonymous:
        "Anonymous",
      LastNameAnonymous:
        "Patron",
    },
  },
  de: {
    translation: {
      welcome:
        "Bienvenido",
      want:
        "Rufe deine Kompensation hier auf!",
      enter:
        "Löse entweder deine persönliche FlowerMe-ID ein oder rufe deinen Kompensationsaccount auf! Du kannst optional deine Email mit einem Kompensationsaccount verknüpfen!",
      flowermeContributionCode:
        "FlowerMe Beitragscode",
      enterEmail:
        "Emailadresse eingeben",
      acceptPrivacyPolicy:
        "Ich stimme der <1>Datenschutzerklärung</1> zu",
      linkEmail:
        "Ich möchte ein kostenloses Naturschutz-Sammelkonto erstellen, um meine zukünftigen Beiträge zu sammeln.",
      accessCompensation:
        "Du möchtest dein Naturschutz-Sammelkonto aufrufen?",
      accessCompensationLink:
        "Klicke hier",
      redeemFlowerMe:
        "FlowerMe Beitragscode einlösen",
      newToFlowerMe:
        "Bist du noch nicht Teil der FlowerMe Initiative?",
      getInTouch:
        "Trete noch heute mit uns in Kontakt, um deine Käufe zu kompensieren!",
      surname:
        "Vorname",
      lastname:
        "Nachname",
      email:
        "Email-Adresse",
      newsletterAgreement:
        "Ich stimme zu, dass ich den Newsletter unter der Beachtung der <1>Datenschutzrichtlinie</1> erhalten möchte.",
      accessYourCompensation:
        "Zugriff zu deinem Naturschutz- Beitragskonto",
      accessViaCode:
        "Du möchtest nur einen FlowerMe Beitragscode eingeben und deinen Beitrag zu regenerativen Projekten leisten? Klicke hier",
      oops:
        "Ups!",
      codeUnknown:
        "Dein FlowerMe Beitragscode ist unbekannt, bitte überprüfe diesen nochmals. Möchtest du deinen Beitrag kompensieren?",
      successRedeem:
        "Einlösen erfolgreich! Du kannst deine Historie in ein paar Sekunden einsehen.",
      currentContribution:
        "Dein aktueller Beitrag zu FlowerMe’s Nature Vault",
      storedCO2:
        "Gespeichertes CO₂",
      absorbedCO2:
        "Absorbiertes CO₂",
      lastCompensation:
        "Letzte getrackte Kompensation",
      patron:
        "Beschützer",
      protector:
        "Beschützer",
      protectedRainforest:
        "Aktuell geschützte m² in FlowerMe's Ökosystemen",
      supportProjects:
        "Mit deinem Beitrag unterstützt FlowerMe regenerative Projekte rund um die Welt!",
      claimContribution:
        "Du möchtest deinen Naturschutz-Beitrag einem Benutzerkonto zuordnen?",
      enterEmailForClaim:
        "Gebe dafür deine Emailadresse an!",
      claim:
        "Einlösen",
      successEmailClaim:
        "Erfolgreich eingelöst! Wir konnten erfolgreich deinen FlowerMe Beitragscode mit deiner Emailadresse verbinden!",
      storedCO2Tooltip:
        "Gespeichertes CO₂ bezeichnet den Kohlenstoffdioxid, welcher von einer Instanz erfasst und dauerhaft in einem Kohlenstoffspeicher gelagert wird, um eine Freisetzung in die Atmosphäre zu verhindern. Ein typischer Kohlenstoffspeicher ist der Regenwald oder das Moor.",
      absorbedCO2Tooltip:
        "Absorbiertes CO₂ bezeichnet den Kohlenstoffdioxid, welcher von natürlichen Instanzen aufgenommen und aus der Atmosphäre entfernt wird. Dieser CO₂ wird dann in Pflanzen oder Biomasse integriert.",
      rainforestTooltip:
        "Die angegebenen Quadratmeter Regenwald zeigen an, welchen Teil des Regenwalds du aktuell schützt, was durch ein NFT von unserem regenerativen Partnerprojekt nachgewiesen wird. Wenn du mehr darüber erfahren möchtest, kannst du hier auf die Regenwalddaten <1>zugreifen</1>.",
      natureVaultTooltip:
        "Der FlowerMe Nature Vault ist eine Sammlung für regenerative und Erhaltungsprojekte, die Natur und Umwelt unterstützen. Die entsprechende finanzielle Unterstützung wird durch NFTs zertifiziert.",
      cookieBanner:
        "Wir verwenden Cookies, um die Nutzung dieser Anwendung zu ermöglichen, eine proaktive Support-Erfahrung zu generieren und die Nutzung der Website zu analysieren. Indem du diese Anwendung als Unterseite unserer Website weiterhin nutzt, erklärst du, dass du diesen Hinweis gelesen hast und die <1>Datenschutzerklärung</1> sowie die Verwendung von Cookies akzeptierst.",
      cookieAgree:
        "Ich stimme zu",
      loginVerificationModal:
        "Du hast bereits einen Compensate-Account? <1>Klicke hier</1>",
      sendEmailAddress:
        "Daten bestätigen",
      becomeFlowerMePartner:
        "Werde FlowerMe Partner",
      SocialShareText:
        "Jede Blume zählt! Ich habe meine Blumen mit #FlowerMe kompensiert, die einfache und transparente Art, #CO2-Emissionen aus Lieferketten #nachhaltig auszugleichen und unsere Erde zu schützen. Hier geht's zu meiner Kompensation:",
      socialHeader:
        "FlowerMe macht Blumen Klimapositiv!",
      getImprintFooter:
        "Impressum",
      getImprintFooterLink:
        "https://flowerme.xyz/de/impressum/",
      getPrivacyPolicyFooter: 
        "Datenschutzerklärung",
      getPrivacyPolicyFooterLink:
        "https://flowerme.xyz/de/datenschutz/",
      getTAndSFooter:
        "Allgemeine Geschäftsbedingungen (AGB)",
      getTAndSFooterLink:
        "https://flowerme.xyz/de/agb/",
      getLinkFlowerMeHome:
        "https://flowerme.xyz/de/",
      getLinkInitiativePlus:
        "https://flowerme.xyz/de/initiative/",
      getLinkFlowerMeShop:
        "https://shop.flowerme.xyz/de/",
      getLinkFlowerMeAbout:
        "https://flowerme.xyz/de/about/",
      getLinkBecomingPartner:
        "https://shop.flowerme.xyz/de/produkt-kategorie/initiativeplus-unternehmen/",
      CookieConsensButton:
        "Ich möchte angemeldet bleiben",
      howItWorksHeader:
        "So funktioniert das FlowerMe Kompensationstool",
      quantifyExplainer:
        "<strong>1. Berechnung:</strong> Bestimmen Sie die Emissionen Ihrer Blumen mit unserem Quantify-Tool",
      shopRedirectExplainer:
        "<strong>2. Vergleich:</strong> Wähle aus der vorgeschlagenen Auswahl an Kompensationspaketen dein Paket aus",
      compensateYourCode:
        "<strong>3. Kompensation:</strong> Gib deine persönliche FlowerMe-ID online ein und erhalte deine getrackten Kompensationen",
      shareYourImpact:
        "<strong>4. Teile:</strong> Präsentiere deinen Kunden echte Nachhaltigkeit und berichte der Welt darüber",
      SuccessMailchimp:
        "Die Übermittlung deiner Daten war erfolgreich!",
      ContributeWhenCodeError:
        "Möchtest du trotzdem einen <a href='https://shop.flowerme.xyz/store/'>Beitrag leisten</a>?",
      CompensationAccountAccess:
        "Möchtest du auf deinen",
      CompensationAccountAccess2:
        "Kompensationsaccount Zugreifen?",
      orTranslation:
        "oder",
      AcceessShopDoubleReedem:
        "Möchtest du selber deinen Beitrag leisten <a href='https://shop.flowerme.xyz/store/'> und dein eignes Sammelkonto eröffnen? </a>",
      CompensationHistory:
        "Deine Kompensationen auf einen Blick",
      CompensationHelper:
        "Hier erhältst du den Überblick über alle Kompensationen, welche bis heute eingelöst wurden! Der dargestellte Zeitpunkt stellt den Zeitpunkt dar, zu welchem du diesen eingelöst hast!",
      RedeemedCodeHistory:
        "Dein eingelöster Code",
      RedeemedTimestampHistory:
        "Zeitpunkt der Einlösung",
      RedeemedSqaremetersHistory:
        "Geschützte Quadratmeter",
      RedeemedRegionHistory:
        "Geschütztes Ökosystem",
      accessHistoryModal:
        "Gelange hier zu deinem Kompensationsverlauf",
      PatronsProfile:
        "Dein Profil als Beschützer auf FlowerMe:",
      logoutButton:
        "Logout",
      ChangeName:
        "Ändere den Namen des Beschützers!",
      changeNameHelper:
        "Du kannst hier deinen gewünschten Namen eingeben, welche auch öffentlich einsehbar ist:",
      changeDashboardInfo:
        "Bearbeite dein Kompensationskonto",
      provideName:
        "Gewünschter Name",
      sendName:
        "Name Ändern",
      RememberMe:
        "Eingeloggt bleiben",
      projectInfoEstonia:
        "Erfahre mehr über unser Aufforstungsprojekt in Estland",
      estonianProjectOverview:
        "Aufforstung in Estland: Pflanze einen Baum zusammen mit Green Deal",
      estoniaInfo:
        "Eine Pflanzung erfolgt nicht von heute auf morgen. Dafür bedarf es einer gründlichen Vorarbeit. Unser Partner GreenDeal sucht mit bedacht geeignete Pflanzungsorte aus und bewirtschaftet diese. Wir wollen dir hier alle nötigen Infos jederzeit zum Projekt zukommen lassen. Von daher, schaue regelmäßig vorbei, um immer das aktuellste Update hierzu zu erhalten!",
      checkForNewClaim:
        "Prüfen",
      EstoniaOwnOverview:
        "Hier ist ein Überblick über deine aktuellen Projektbeiträge:",
      NumberTreesOverview:
        "Anzahl der Bäume:",
      LastPlantingDate:
        "Letztes Beitragsdatum:",
      PatronsNameEstonia:
        "Die Bäume werden gepflanzt von:",
      estonianAchivable:
        "Unsere innovative Strategie im Kampf gegen den Klimawandel konzentriert sich auf nachhaltige Forstwirtschaft und verbessertes Waldbewirtschaftung, mit besonderem Fokus auf Kohlenstoffbindung. Der Carbon Reserve besteht aus mehreren Carbon Parks, die alle nach der Waldmanagementmethodik betrieben werden, einer Forschungskooperation mit führenden Universitäten. Unsere Verpflichtung besteht darin, alle Wälder innerhalb von 18 Monaten nach dem Projektende (Frühjahr 2025) zu pflanzen, wobei auf sorgfältige Grundstücksauswahl, Bodenanalyse, Baumauswahl, Mineralisierung und detaillierte Planung für maximale Wirkung geachtet wird. Ein engagiertes Team verwaltet jeden Wald unter Anwendung einer wissenschaftlich entwickelten Methodik für maximale Kohlenstoffbindung, wobei alle Carbon Parks PEFC-zertifiziert sind, um die erwartete Managementqualität zu gewährleisten.",
      PlantingDate:
        "Infos zu unserem Partnerprojekt",
      FirstNameAnonymous:
        "Anonymer",
      LastNameAnonymous:
        "Beschützer",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "de", // set the default language
  keySeparator: false,
  fallbackLng: 'en',
      detection: {
      order: ['navigator', 'localStorage'],
    },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n; 
