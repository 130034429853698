import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setData, setState } from "../../store/actionCreators";

import { AxiosResponse } from "axios";
import sendRequest from "../../services";

import { EmailModal } from "../../modals";

import { CompensationDashboard } from "./CompensationDashboard";
import { CompensationData } from "./CompensationData";

import { IData } from "../../type";

import Cookies from 'js-cookie';

import { useNavigate } from 'react-router-dom';

export const Compensation = () => {
  const navigate = useNavigate();
  
  const [sharingCode, setSharingCode] = useState("");

  const { sharingSlug } = useParams();

  const dispatch = useDispatch();

  const state = useSelector((state: any) => state.state.state);
  const modalSelector = useSelector((state: any) => state.modal);
  const dataSelector = useSelector((state: any) => state.data);

  const onSharingSuccess = (res: AxiosResponse) => {
    const data: IData = {
      last_comp_tracked: res.data.last_comp_tracked,
      stored_co: res.data.stored_co,
      absorted_co: res.data.absorted_co,
      protector: res.data.protector,
      currently_protected: res.data.currently_protected,
      email: res.data.email,
      shared_slug: res.data.shared_slug,
      claiming_code: res.data.claiming_code,
    };

    dispatch(setData(data));
    setSharingCode(res.data.shared_slug);
    dispatch(setState({ state: true }));
  };

  useEffect(() => {
    const existingCookieValue = Cookies.get("SharingSlug");
    if (existingCookieValue) {
      navigate(`/compensation/${existingCookieValue}`);
    }
  }, []);

  useEffect(() => {
    if (
      sharingSlug &&
      window.location.pathname !==
        `/compensation/${dataSelector.shared_slug}/${dataSelector.claiming_code}`
    ) {
      sendRequest(
        "get",
        `/api/v1/main/claim?sharing_slug=${sharingSlug}`,
        undefined,
        onSharingSuccess,
        undefined,
        undefined,
      );
    } 
  }, [sharingSlug]);

  console.log("state: ", state);

  return (
    <>
      {modalSelector.email && <EmailModal onSuccess={onSharingSuccess} />}
      <div className="compensation-bg"></div>
      <div className="compensation">
        {!state ? (
          <CompensationDashboard setSharedSlug={setSharingCode} />
        ) : (
          <CompensationData sharingCode={sharingCode} />
        )}
      </div>
    </>
  );
};
