import React, { useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "../../store/actionCreators";
import { Input, Modal, Button } from "../../components";
import sendRequest from "../../services";
import { useTranslation } from "react-i18next";

export const NameChangeModal = () => {
    const [name, setName] = useState("");
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const dataSelector = useSelector((state: any) => state.data);
    const sharingSlug = dataSelector.shared_slug;
  
    const handleClose = () => {
      dispatch(toggleModal("nameChange"));
    };

    const onSuccess = () => {
      const isSuccess = true;
  
      if (isSuccess) {
          setTimeout(() => {
              if (typeof window !== 'undefined' && window.location) {
                  window.location.reload();
              }
          }, 2000);
      }
  };

    const handleNameChange = () => {
      console.log(name)
      sendRequest(
        "POST",
        `/api/v1/main/patron/${name}/${sharingSlug}`,
        undefined,
        onSuccess,
        undefined,
        undefined,
      );
    }

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    };
  
    return (
      <Modal
      type="nameChange"
      title={t("ChangeName")}
      helper={t("changeNameHelper")}
      handleClose={handleClose}
      classname="name"
      >
        <div className='nameChange'>
          <Input
            placeholder={t("provideName")}
            name="name"
            value={name}
            onChange={handleChangeName}
          />
          <Button
            title={t("sendName")}
            handleClick={handleNameChange}
          />
        </div>
      </Modal>
    );
  };