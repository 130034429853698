import React from "react";
import { useTranslation, Trans } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <p id="copyright" className="footer-element">
        Copyright 2023 © FlowerMe
      </p>
      <span className="footer-element">
        <a className="footer-element-link" href={t('getImprintFooterLink')}> 
          {t("getImprintFooter")}
        </a>
        |
        <a
          className="footer-element-link" href={t('getPrivacyPolicyFooterLink')}>
          {t("getPrivacyPolicyFooter")}
        </a>
        |
        <a
          className="footer-element-link" href={t('getTAndSFooterLink')}>
          {t("getTAndSFooter")}
        </a>
      </span>
    </footer>
  );
};
