import React from "react";

import oops from "../../img/oops.svg";
import check from "../../img/check.svg";
import close from "../../img/close.svg";
import flower from "../../img/flowerme_website_logo.png";
import estonia from "../../img/estonia_tree.png";

interface ModalType {
  title: string;
  handleClose: (type: string) => void;
  type: "error" | "success" | "login" | "email" | "claimed" | "flower" | "history" | "nameChange" | "estonia";
  helper?: string;
  classname?: string;
  children: React.ReactNode;
}

export const Modal = ({
  title,
  handleClose,
  type,
  helper,
  classname,
  children,
}: ModalType) => {
  const handleCloseClick = () => {
    handleClose(type);
  };

  return (
    <div className="modal">
      <div className={`modal-container ${classname}`}>
        <img
          className="modal-container__close"
          src={close}
          alt="close"
          onClick={handleCloseClick}
        />
        <div className="modal-container-content">
          {(type === "error" || type === "success" || type === "claimed") && (
            <img
              className="modal-container-content__img"
              src={type === "error" ? oops : check}
              alt="info"
            />
          )}
          {type === "flower" && (
            <img
              className="modal-container-content__img"
              src={flower}
              alt="info"
            />
          )}
          {type === "estonia" && (
            <img
              className="modal-container-content__imgProject"
              src={estonia}
              alt="info"
            />
          )}
          <h1 className="modal-container-content__title">{title}</h1>
          <p className="modal-container-content__helper">{helper}</p>
          <p className="modal-container-content__content">{children}</p>
        </div>
      </div>
    </div>
  );
};
