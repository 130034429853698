import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Footer, Drawer } from "../../components";
import {
  ErrorModal,
  LoginModal,
  SuccessModal,
  ClaimedModal,
  FlowerModal,
  HistoryModal,
  NameChangeModal,
  EstoniaModal,
} from "../../modals";
import { toggleDrawer } from "../../store/actionCreators";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";
import CookieConsent from "react-cookie-consent";

import Cookies from "js-cookie";

import { useTranslation, Trans } from "react-i18next";

const chains = [arbitrum, mainnet, polygon];
const projectId = "fc367a1627a4842ae15a610d39eaf336";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

interface LayoutType {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutType) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isCookie, setIsCookie] = useState(false);
  const modalSelector = useSelector((state: any) => state.modal);
  const drawerSelector = useSelector((state: any) => state.drawer);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  useEffect(() => {
    Cookies.get("weUseCookies") ? setIsCookie(true) : setIsCookie(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isCookie && <div className="cookie-bg"></div>}
      <WagmiConfig config={wagmiConfig}>
        <Drawer
          isOpen={drawerSelector.isOpen}
          toggleDrawer={handleToggleDrawer}
          isActive={screenWidth <= 768}
        />
        {modalSelector.flower && <FlowerModal />}
        {modalSelector.error && <ErrorModal />}
        {modalSelector.success && <SuccessModal />}
        {modalSelector.login && <LoginModal />}
        {modalSelector.claimed && <ClaimedModal />}
        {modalSelector.history && <HistoryModal />}
        {modalSelector.nameChange && <NameChangeModal />}
        {modalSelector.estonia && <EstoniaModal />}
        <Header isActive={screenWidth > 768} />
        {children}
        <Footer />
      </WagmiConfig>
      <CookieConsent
        buttonText={t("cookieAgree")}
        cookieName="weUseCookies"
        style={{
          color: "#FFF",
          fontFamily: "Lato",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "18px",
          background: "rgba(1, 83, 81, 0.80)",
          zIndex: 10001,
        }}
        onAccept={() => {
          setIsCookie(true);
        }}
        buttonStyle={{
          borderRadius: "30px",
          border: "2px solid var(--brand-colors-orange, #FE6728)",
          background: "var(--brand-colors-orange, #FE6728)",
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Babe Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "16px",
          textTransform: "capitalize",
        }}
      >
        <Trans i18nKey="cookieBanner">
          We use cookies to provide the usage of this application, to generate a
          proactive support experience and to analyze the usage of the website.
          By continuing to use this application as a subpage of our website, you
          agree to have read this notice and accept the{" "}
          <a
            href="https://flowerme.xyz/privacy-policy/"
            target="_blank"
            className="privacy-policy"
          >
            Privacy Policy
          </a>{" "}
          and the use of cookies.
        </Trans>
      </CookieConsent>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
};
