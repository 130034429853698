import React, { useEffect, useState } from "react";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

import { useSelector, useDispatch } from "react-redux";

import air from "../../img/air.svg";
import info from "../../img/info.svg";
import home from "../../img/home.svg";
import nature from "../../img/nature.svg";
import bubble from "../../img/bubble.svg";
import twitter from "../../img/sharebutton_x_icon.svg";
import reaction from "../../img/reaction.svg";
import linkedin from "../../img/sharebutton_linkedin_icon.svg";
import facebook from "../../img/sharebutton_facebook_icon.svg";
import whatsapp from "../../img/sharebutton_whatsapp_icon.svg";
import mailicon from "../../img/sharebutton_mail_icon.svg";

import { Input, Button } from "../../components";

import { toggleModal, setData } from "../../store/actionCreators";

import sendRequest from "../../services";
import { AxiosResponse } from "axios";

import { useTranslation, Trans } from "react-i18next";

import Cookies from 'js-cookie';

import { useNavigate } from 'react-router-dom';

export const CompensationData = ({ sharingCode }: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  
  const shareUrl = "https://compensation.flowerme.xyz";
  const title = t("SocialShareText");
  const socialHeader = t("socialShareHeader");

  const [claimed_email, setClaimedEmail] = useState("");
  const [successSubscribed, SetSuccessSubscribed] = useState(false);
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const dispatch = useDispatch();

  const currLang = useSelector((state: any) => state.lang);

  const handleRemoveCookie = () => {
    return new Promise((resolve, reject) => {
      Cookies.remove('SharingSlug', { path: '/' });
      Cookies.remove('Cookie', { path: '/' });
      Promise.all([
        Cookies.remove('SharingSlug', { path: '/' }),
        Cookies.remove('Cookie', { path: '/' }),
      ])
      .then(() => {
        reloadPage();
      })
    });
  };

  const handleChangePrivacy = () => {
    setPrivacy(!privacy);
  };

  const reloadPage = () => {
    const isSuccess = true;

    if (isSuccess) {
        setTimeout(() => {
            if (typeof window !== 'undefined' && window.location) {
                window.location.reload();
            }
        }, 1000);
    }
};

  const compensationData = useSelector((state: any) => state.data);
  const code = useSelector((state: any) => state.code);

  const handleChangeEmail = (e: any) => {
      setClaimedEmail(e.target.value);
  };

  const handleClaimSuccess = (res: AxiosResponse) => {
    if (res.data.sharing_slug !== null) {
      window.location.pathname = `/compensation/${res.data.sharing_slug}`;
    }
    dispatch(toggleModal("claimed"));
  };

  const handleSendForm = () => {
    SetSuccessSubscribed(false);
    if (privacy) {
      sendRequest(
        "POST",
        "/api/v1/main/form",
        {
          surname: surname,
          lastname: lastname,
          email_address: claimed_email,
          lang: currLang.language,
        },
        onFormSuccess,
        onFormError,
        undefined,
      );
    }
  };

  const onFormError = () => {
    console.log("something goes wrong...");
  };

  const onFormSuccess = (res: AxiosResponse) => {
    SetSuccessSubscribed(true);
    setClaimedEmail("");
    setSurname(t("FirstNameAnonymous"));
    setLastname(t("LastNameAnonymous"));
  };

  const handleHistory = () => {
    dispatch(toggleModal("history"));
  }

  const handleEstonia = () => {
    dispatch(toggleModal("estonia"));
  }

  const handleChangeName = () => {
    dispatch(toggleModal("nameChange"));
  }

  const handleLoadClaimed = (res: AxiosResponse) => {
    dispatch(setData(res.data));
  };

  useEffect(() => {
    const existingCookieValue = Cookies.get('Cookie');

    if (existingCookieValue) {
      const newCookieName = 'SharingSlug';
      const newCookieValue = compensationData.shared_slug;
      Cookies.set(newCookieName, newCookieValue, {
        expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        path: '/',
      });
      console.log(newCookieValue);
    }
  }, []);

  const handleClaimContribution = () => {
    if (privacy) {
      if (claimed_email !== "" && claimed_email) {
        sendRequest(
          "PATCH",
          `/api/v1/main/claim/${code.code}`,
          { claiming_code: code.code, claimed_email },
          handleClaimSuccess,
          undefined,
          undefined,
        );
        handleSendForm();
      }
    }
  };

  useEffect(() => {
    console.log(
      window.location.pathname,
      `/compensation/${compensationData.shared_slug}/${compensationData.claiming_code}`,
    );
    if (
      window.location.pathname ===
      `/compensation/${compensationData.shared_slug}/${compensationData.claiming_code}`
    ) {
      sendRequest(
        "get",
        `/api/v1/main/claim/${compensationData.shared_slug}/${compensationData.claiming_code}`,
        undefined,
        handleLoadClaimed,
        undefined,
        undefined,
      );
    }
  }, []);

  return (
    <>
      <div
        className="compensation-block"
        id={`${compensationData?.email === null ? "" : "margin"}`}
      >
        <div className="compensation-block-content">
          <h1 className="compensation-block-content-title">
            {t("currentContribution")}
            {/* Your current contribution to the FlowerMe nature vault */}
            <img
              src={info}
              alt="info"
              className="compensation-block-tooltip__img"
              data-tooltip-id="title-tooltip"
              data-tooltip-content={t("natureVaultTooltip")}
            />
            <Tooltip id="title-tooltip" className="tooltip__content" />
          </h1>
          <div className="compensation-block-content-grid">
            <div
              className="compensation-block-content-grid-element"
              id="element1"
            >
              <img
                className="compensation-block-content-grid-element__img"
                src={bubble}
                alt="bubble"
              />
              <div className="compensation-block-content-grid-element-block">
                <h3 className="compensation-block-content-grid-element-block__title">
                  {/* Stored CO₂ */}
                  {t("storedCO2")}
                  <img
                    src={info}
                    alt="info"
                    className="compensation-block-tooltip__img"
                    data-tooltip-id="stored-tooltip"
                    data-tooltip-content={t("storedCO2Tooltip")}
                  />
                  <Tooltip id="stored-tooltip" className="tooltip__content" />
                </h3>
                <p className="compensation-block-content-grid-element-block__helper">
                  {compensationData.stored_co !== "null"
                    ? compensationData.stored_co
                    : "0"}{" "}
                  Kg
                </p>
              </div>
            </div>
            <div
              className="compensation-block-content-grid-element"
              id="element2"
            >
              <img
                className="compensation-block-content-grid-element__img"
                src={air}
                alt="air"
              />
              <div className="compensation-block-content-grid-element-block">
                <h3 className="compensation-block-content-grid-element-block__title">
                  {/* Absorbed CO₂ */}
                  {t("absorbedCO2")}
                  <img
                    src={info}
                    alt="info"
                    className="compensation-block-tooltip__img"
                    data-tooltip-id="absorbed-tooltip"
                    data-tooltip-content={t("absorbedCO2Tooltip")}
                  />
                  <Tooltip id="absorbed-tooltip" className="tooltip__content" />
                </h3>
                <p className="compensation-block-content-grid-element-block__helper">
                  {compensationData.absorted_co !== "null"
                    ? compensationData.absorted_co
                    : "0"}{" "}
                  g
                </p>
              </div>
            </div>
            <div
              className="compensation-block-content-grid-element"
              id="element3"
            >
              <img
                className="compensation-block-content-grid-element__img"
                src={home}
                alt="home"
              />
              <div className="compensation-block-content-grid-element-block">
                <h3 className="compensation-block-content-grid-element-block__title">
                  {/* Last compensation tracked */}
                  {t("lastCompensation")}
                </h3>
                <p className="compensation-block-content-grid-element-block__helper">
                  {compensationData.last_comp_tracked !== "null"
                    ? compensationData.last_comp_tracked
                    : "xx/xx/xx"}
                </p>
              </div>
            </div>
            <div
              className="compensation-block-content-grid-element"
              id="element4"
            >
              <img
                className="compensation-block-content-grid-element__img"
                src={reaction}
                alt="reaction"
              />
              <div className="compensation-block-content-grid-element-block __protector-block">
                <h3 className="compensation-block-content-grid-element-block__title">
                  {/* Protector */}
                  {t("protector")}
                </h3>
                <p className="compensation-block-content-grid-element-block__helper __protector">
                  {compensationData.protector !== "null"
                    ? compensationData.protector
                    : "null"}
                </p>
              </div>
            </div>
            <div
              className="compensation-block-content-grid-element"
              id="element5"
            >
              <img
                className="compensation-block-content-grid-element__img"
                src={nature}
                alt="nature"
              />
              <div className="compensation-block-content-grid-element-block">
                <h3 className="compensation-block-content-grid-element-block__title">
                  {/* Currently protected m² of rainforest */}
                  {t("protectedRainforest")}
                  <img
                    src={info}
                    alt="info"
                    className="compensation-block-tooltip__img"
                    data-tooltip-id="protected-tooltip"
                  />
                  <Tooltip
                    id="protected-tooltip"
                    className="tooltip__content"
                    clickable={true}
                  >
                    <Trans i18nKey="rainforestTooltip">
                      The shown square meters rainforest indicate the rainforest
                      you are currently protecting, which is proofed by an NFT
                      from our regenerative partner project. If you want to know
                      more about it, you can access the rainforest
                      <a href="https://explorer.fundtheplanet.net/rft/s001-001">
                        here
                      </a>
                      .
                    </Trans>
                  </Tooltip>
                </h3>
                <p className="compensation-block-content-grid-element-block__helper">
                  {compensationData.currently_protected !== "null"
                    ? compensationData.currently_protected
                    : "0"}{" "}
                  m²
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="compensation-block-socials">
          <h2 className="compensation-block-socials__title">
            {t("supportProjects")}
            {/* With your contribution, FlowerMe helps regenerative projects! */}
          </h2>
          <div className="compensation-block-socials-row">
            <TwitterShareButton
              url={`${shareUrl}/compensation/${sharingCode}`}
              title={title}
            >
              <img
                className="compensation-block-socials-row__img"
                src={twitter}
                alt="twitter"
              />
            </TwitterShareButton>
            <LinkedinShareButton
              url={`${shareUrl}/compensation/${sharingCode}`}
              title={socialHeader}
              summary={title}
            >
              <img
                className="compensation-block-socials-row__img"
                src={linkedin}
                alt="linkedin"
              />
            </LinkedinShareButton>
            <FacebookShareButton
              url={`${shareUrl}/compensation/${sharingCode}`}
              quote={title}
            >
              <img
                className="compensation-block-socials-row__img"
                src={facebook}
                alt="facebook"
              />
            </FacebookShareButton>
            <WhatsappShareButton
              url={`${shareUrl}/compensation/${sharingCode}`}
              title={title}
            >
              <img
                className="compensation-block-socials-row__img"
                src={whatsapp}
                alt="whatsapp"
              />
            </WhatsappShareButton>
            <EmailShareButton
              url={`${shareUrl}/compensation/${sharingCode}`}
              subject={socialHeader}
              body={title}
            >
              <img
                className="compensation-block-socials-row__img"
                src={mailicon}
                alt="email_icon"
              />
            </EmailShareButton>
          </div>
        </div>
      </div>
      {compensationData.email !== null && (
          <div className="compensation-contribution-account">
            <div className="compensation-contribution-account-profile">
              <h1 className="compensation-contribution-account-profile-header">
                {t("PatronsProfile")}
              </h1>
              <div className="compensation-contribution-account-profile-buttons">
                <Button title={t("accessHistoryModal")} handleClick={handleHistory} />
              </div>
              <div className="compensation-contribution-account-profile-buttons">
                <Button title={t("changeDashboardInfo")} handleClick={handleChangeName} />
              </div>
              <div className="compensation-contribution-account-profile-buttons">
                <Button title={t("projectInfoEstonia")} handleClick={handleEstonia} />
              </div>
              <div className="compensation-contribution-account-profile-buttons">
                <Button title={t("logoutButton")} handleClick={handleRemoveCookie} />
              </div>
            </div>
          </div>
      )}
      {compensationData.email === null && (
        <div className="compensation-contribution">
          <div className="compensation-contribution-content">
            <div className="compensation-contribution-content-info">
              <h1 className="compensation-contribution-content-info-header">
                {t("claimContribution")}
              </h1>
              <p className="compensation-contribution-content-info-text">
                {t("enterEmailForClaim")}
              </p>
            </div>
            <div className="compensation-contribution-content-buttons">
              <Input
                placeholder="Enter Email"
                name="emailaddress"
                value={claimed_email}
                onChange={handleChangeEmail}
              />
              <Button title={t("checkForNewClaim")} handleClick={handleClaimContribution} />
            </div>
            <div className="compensation-contribution-content-privacy">
              <input
                type="checkbox"
                id="privacy"
                required
                checked={privacy}
                onChange={handleChangePrivacy}
              />
              <label htmlFor="link">
                {t("linkEmail")}
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
