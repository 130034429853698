import { ModalState, ModalAction } from "../type";
import * as actionTypes from "./actionTypes";

const initialState: ModalState = {
  error: false,
  success: false,
  login: false,
  email: false,
  claimed: false,
  flower: false,
  history: false,
  nameChange: false,
  estonia: false,
};

const modalReducer = (
  state: ModalState = initialState,
  action: ModalAction,
): ModalState => {
  switch (action.type) {
    case actionTypes.TOGGLE_MODAL:
      return {
        ...state,
        [action.modalType!]: !state[action.modalType!],
      };
    default:
      return state;
  }
};

export default modalReducer;
