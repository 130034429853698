import { StateState, StateAction } from "../type";
import * as actionTypes from "./actionTypes";

const initialState: StateState = {
  state: false,
};

const langReducer = (
  state: StateState = initialState,
  action: StateAction,
): StateState => {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return {
        state: action.state!.state,
      };
    default:
      return state;
  }
};

export default langReducer;
