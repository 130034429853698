import React, { useState, useEffect } from "react";

import sendRequest from "../../services";

import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";

import { useDispatch, useSelector } from "react-redux";
import {
  setData,
  toggleDrawer,
  toggleModal,
  setLang,
  setState,
} from "../../store/actionCreators";

import { Button } from "..";

import UK from "../../img/uk.svg";
import DE from "../../img/de.webp";
import op from "../../img/open.svg";
import logo from "../../img/flowerme_website_logo.png";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

interface HeaderType {
  isActive: boolean;
}

export const Header = ({ isActive }: HeaderType) => {
  const [addr, setAddr] = useState("");
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<any>(null);
  const { open, close } = useWeb3Modal();
  const { address, isConnected } = useAccount();

  const dispatch = useDispatch();

  const currLang = useSelector((state: any) => state.lang);

  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const { t } = useTranslation();

  const changeLanguage = (lng: string) => {
    dispatch(setLang({ language: lng }));
    i18n.changeLanguage(lng);
  };

  const handleClick = () => {
    open();
  };

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  const handleShowSuccess = (res: AxiosResponse) => {
    dispatch(setData(res.data));
    dispatch(toggleModal("success"));
    setTimeout(() => {
      dispatch(setState({ state: true }));
      dispatch(toggleModal("success"));
    }, 2000);
  };

  const getNFTs = async () => {
    sendRequest(
      "POST",
      "/api/v1/main/by-wallet",
      { wallet: address?.toLowerCase() },
      handleShowSuccess,
      undefined,
      undefined,
    );
  };

  const handleMouseEnter = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setIsLanguageOpen(true);
  };

  const handleMouseLeave = () => {
    setHoverTimeout(
      setTimeout(() => {
        setIsLanguageOpen(false);
      }, 500), // 500ms delay, adjust as necessary
    );
  };

  useEffect(() => {
    if (isActive) {
      if (isConnected) {
        setAddr(`${address?.slice(0, 5)}...${address?.slice(-5)}`);
        getNFTs();
      } else {
        if (addr) {
          navigate("/");
        }
      }
    }
  }, [isConnected, isActive]);

  return (
    <header className="header">
      <div className="header-container">
        <img
          className="header-container-logo"
          src={logo}
          alt="logo"
          onClick={() => (window.location.href = "https://compensation.flowerme.xyz")}
        />
        <ul className="header-container-list">
          <li>
            <a href={t('getLinkFlowerMeHome')}>Home</a>
          </li>
          <li>
            <a href={t('getLinkInitiativePlus')}>Initiative+</a>
          </li>
          <li>
            <a href={t('getLinkFlowerMeShop')}>Shop</a>
          </li>
          <li>
            <a href={t('getLinkFlowerMeAbout')}>About</a>
          </li>
          <li>
            <div
              className="header-container-list-lang"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={currLang.language === "en" ? UK : DE}
                alt="english"
                className="header-container-list-lang-img"
              />
              <img
                src={op}
                alt="open"
                className="header-container-list-lang-img"
              />
              {isLanguageOpen && (
                <div
                  className="language-modal"
                  onMouseEnter={() => {
                    if (hoverTimeout) {
                      clearTimeout(hoverTimeout);
                      setHoverTimeout(null);
                    }
                  }}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={UK}
                    alt="english"
                    onClick={() => changeLanguage("en")}
                  />
                  <img
                    src={DE}
                    alt="germany"
                    onClick={() => changeLanguage("de")}
                  />
                </div>
              )}
            </div>
          </li>
        </ul>
        {!isConnected ? (
          <Button
            title="Connect Wallet"
            handleClick={handleClick}
            classname="hbtn"
          />
        ) : (
          <Button
            title={addr}
            handleClick={handleClick}
            classname="hbtn active"
          />
        )}
        <div
          id="drawer-toggle-button"
          className="header-container-menu"
          onClick={handleToggleDrawer}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="14"
            viewBox="0 0 21 14"
            fill="none"
            id="drawer-toggle-button"
          >
            <path
              d="M1.78783 2.82617H19.2118C19.9378 2.82617 20.5758 2.18817 20.5758 1.46217C20.5758 0.736172 19.9378 0.0761719 19.2118 0.0761719H1.78783C1.06183 0.0761719 0.423828 0.714172 0.423828 1.46217C0.423828 2.21017 1.06183 2.82617 1.78783 2.82617ZM1.78783 8.32617H19.2118C19.9378 8.32617 20.5758 7.68817 20.5758 6.96217C20.5758 6.23617 19.9378 5.57617 19.2118 5.57617H1.78783C1.06183 5.57617 0.423828 6.21417 0.423828 6.96217C0.423828 7.71017 1.06183 8.32617 1.78783 8.32617ZM1.78783 13.8262H19.2118C19.9378 13.8262 20.5758 13.1882 20.5758 12.4622C20.5758 11.7362 19.9378 11.0762 19.2118 11.0762H1.78783C1.06183 11.0762 0.423828 11.7142 0.423828 12.4622C0.423828 13.2102 1.06183 13.8262 1.78783 13.8262Z"
              fill="#FFF5EE"
              id="drawer-toggle-button"
            />
          </svg>
        </div>
      </div>
    </header>
  );
};
