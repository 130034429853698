import React from "react";

interface InputType {
  placeholder: string;
  name: string;
  value: string;
  onChange: (e: any) => void;
}

export const Input = ({ placeholder, name, value, onChange }: InputType) => {
  return (
    <>
      <input
        className="input"
        placeholder={placeholder}
        id={name}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
