import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { toggleModal, setState } from "../../store/actionCreators";

import { Modal } from "../../components";

export const FlowerModal = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const dataSelector = useSelector((state: any) => state.data);

  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(toggleModal("flower"));
  };

  const handleNavigateResult = () => {
    dispatch(toggleModal("flower"));
    dispatch(setState({ state: true }));
    navigate(
      `/compensation/${dataSelector.shared_slug}/${dataSelector.claiming_code}`,
    );
  };

  return (
    <Modal
      type="flower"
      title={`Your FlowerMe contribution code was already claimed on the ${dataSelector.last_comp_tracked}! `}
      // helper={t("successRedeem")}
      handleClose={handleClose}
      classname="success"
    >
      <div>
        <p>
        {t("CompensaionAccountAccess")}{" "}
          <a onClick={handleNavigateResult}>{t("CompensationAccountAccess2")}</a>
        </p>
        <p>{t("orTranslation")}</p>
        <p>
          <div dangerouslySetInnerHTML={{ __html: t('AcceessShopDoubleReedem') }} />
        </p>
      </div>
    </Modal>
  );
};
