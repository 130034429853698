import React from "react";
import "./i18n";

import { Routes, Route, Navigate } from "react-router-dom";

import { Compensation } from "./pages";
import { Layout } from "./views";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/compensation"
          element={
            <Layout>
              <Compensation />
            </Layout>
          }
        />
        <Route
          path="/compensation/:sharingSlug"
          element={
            <Layout>
              <Compensation />
            </Layout>
          }
        />
        <Route
          path="/compensation/:sharingSlug/:claimedCode"
          element={
            <Layout>
              <Compensation />
            </Layout>
          }
        />
        <Route path="*" element={<Navigate to="/compensation" />} />
      </Routes>
    </div>
  );
}

export default App;
