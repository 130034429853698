import React, { useState, ChangeEvent } from "react";

import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "../../store/actionCreators";

import { Input, Modal, Button } from "../../components";
import sendRequest from "../../services";
import axios, { AxiosResponse } from "axios";

import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie';

export const EmailModal = ({ onSuccess }: any) => {
  const [email, setEmail] = useState("");

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleModal("email"));
  };

  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSuccess = (res: AxiosResponse) => {
    handleClose();
    onSuccess(res);
  };

  const handleCheckboxClick = () => {
    const cookieName = "Cookie";
    const cookieValue = "DateLog";
    Cookies.set(cookieName, cookieValue, {
      expires: new Date(Date.now() + 60 * 24 * 60 * 60 * 1000),
      path: '/',
    });
  };

  const handleSendEmail = () => {
    sendRequest(
      "get",
      `/api/v1/main/claim?email=${email.toLowerCase()}`,
      undefined,
      handleSuccess,
      undefined,
      undefined,
    );
  };

  return (
    <Modal
      type="email"
      title={t("accessYourCompensation")}
      handleClose={handleClose}
    >
      <div className="email">
        <label htmlFor="email">{t("email")}</label>
        <Input
          placeholder=""
          name="email"
          value={email}
          onChange={handleChangeEmail}
        />
        <label>
          <input type="checkbox" id="rememberMe" onChange={handleCheckboxClick}/>
          {t("CookieConsensButton")}
        </label>
        <Button
          title="Log in"
          handleClick={handleSendEmail}
          classname="email__button"
        />
        <p className="text">
          <Trans i18nKey="accessCompensation">
            You want to access your compensation through your linked account?{" "}
          </Trans>
          <a onClick={handleClose} className="ml-1">
            <Trans i18nKey="accessCompensationLink">Click here</Trans>
          </a>

          {/* You want to access you complete compensation?{" "}
          <a onClick={handleClose}>Click here</a> */}
        </p>
      </div>
    </Modal>
  );
};
