import { CodeState, CodeAction } from "../type";
import * as actionTypes from "./actionTypes";

const initialState: CodeState = {
  code: "",
};

const codeReducer = (
  state: CodeState = initialState,
  action: CodeAction,
): CodeState => {
  switch (action.type) {
    case actionTypes.SET_CODE:
      return {
        ...state,
        code: action.codeAction?.code ?? "",
      };
    default:
      return state;
  }
};

export default codeReducer;
