import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { toggleModal } from "../../store/actionCreators";

import { Button, Input, Modal } from "../../components";
import { useTranslation } from "react-i18next";

export const LoginModal = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(toggleModal("login"));
  };

  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleChangeCode = (e: any) => {
    setCode(e.target.value);
  };

  const handleLogin = () => {};

  return (
    <Modal
      type="login"
      title="Login to FlowerMe"
      handleClose={handleClose}
      classname="lg"
    >
      <div className="login">
        <label className="login-label" htmlFor="login-email">
          {/* E-mail */}
          {t("email")}
        </label>
        <Input
          placeholder=""
          name="login-email"
          value={email}
          onChange={handleChangeEmail}
        />
        <label className="login-label" htmlFor="login-code">
          {/* Tracking Code */}
          {t("flowermeContributionCode")}
        </label>
        <Input
          placeholder=""
          name="login-code"
          value={code}
          onChange={handleChangeCode}
        />
        <div className="login-block">
          <input
            className="login-block-input"
            type="checkbox"
            id="remember"
            name="remember"
          />
          <label className="login-block-text" htmlFor="remeber">
            {t("RememberMe")}
          </label>
        </div>
        <Button title="Log in" handleClick={handleClose} />
        <p className="login-text">
          {/* You want to access you complete compensation? <a>Click here</a> */}
          {t("accessCompensation")}
        </p>
      </div>
    </Modal>
  );
};
