import React, { useState, useEffect } from "react";
import { Button } from "..";

import sendRequest from "../../services";

import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";

import { useDispatch } from "react-redux";
import { setData, toggleModal, setState } from "../../store/actionCreators";

import UK from "../../img/uk.svg";
import DE from "../../img/de.webp";
import op from "../../img/open.svg";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

interface DrawerType {
  isOpen: boolean;
  toggleDrawer: () => void;
  isActive: boolean;
}

export const Drawer = ({ isOpen, toggleDrawer, isActive }: DrawerType) => {
  const [addr, setAddr] = useState("");
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<any>(null);
  const [currLang, setCurrLang] = useState("en");
  const { open, close } = useWeb3Modal();
  const { address, isConnected } = useAccount();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const { t } = useTranslation();

  const changeLanguage = (lng: string) => {
    setCurrLang(lng);
    i18n.changeLanguage(lng);
  };

  const handleClick = () => {
    handleToggleDrawer();
    open();
  };

  const handleToggleDrawer = () => {
    toggleDrawer();
  };

  const handleShowSuccess = (res: AxiosResponse) => {
    dispatch(setData(res.data));
    dispatch(toggleModal("success"));
    setTimeout(() => {
      dispatch(setState({ state: true }));
      dispatch(toggleModal("success"));
    }, 2000);
  };

  const getNFTs = async () => {
    sendRequest(
      "POST",
      "/api/v1/main/by-wallet",
      { wallet: address?.toLowerCase() },
      handleShowSuccess,
      undefined,
      undefined,
    );
  };

  const handleMouseEnter = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setIsLanguageOpen(true);
  };

  const handleMouseLeave = () => {
    setHoverTimeout(
      setTimeout(() => {
        setIsLanguageOpen(false);
      }, 500), // 500ms delay, adjust as necessary
    );
  };

  useEffect(() => {
    if (isActive) {
      if (isConnected && !!!addr) {
        setAddr(`${address?.slice(0, 5)}...${address?.slice(-5)}`);
        getNFTs();
      } else {
        if (addr) {
          navigate("/");
        }
      }
    }
  }, [isConnected, isActive]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        isOpen &&
        !document.querySelector(".drawer")?.contains(event.target) &&
        event.target.id !== "drawer-toggle-button"
      ) {
        toggleDrawer();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, toggleDrawer]);

  return (
    <div className={`drawer ${isOpen ? "open" : ""}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={handleToggleDrawer}
      >
        <g clipPath="url(#clip0_2886_4157)">
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2886_4157">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className="drawer-content">
        <a href={t('getLinkFlowerMeHome')} target="_blank" rel="noopener noreferrer">Home</a>
        <a href={t('getLinkInitiativePlus')} target="_blank" rel="noopener noreferrer">Initiative+</a>
        <a href={t('getLinkFlowerMeShop')} target="_blank" rel="noopener noreferrer">Shop</a>
        <a href={t('getLinkFlowerMeAbout')} target="_blank" rel="noopener noreferrer">About</a>
        <div
          className="header-container-list-lang"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={currLang === "en" ? UK : DE}
            alt="english"
            className="header-container-list-lang-img"
          />
          <img src={op} alt="open" className="header-container-list-lang-img" />
          {isLanguageOpen && (
            <div
              className="language-modal"
              onMouseEnter={() => {
                if (hoverTimeout) {
                  clearTimeout(hoverTimeout);
                  setHoverTimeout(null);
                }
              }}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={UK}
                alt="english"
                onClick={() => changeLanguage("en")}
              />
              <img
                src={DE}
                alt="germany"
                onClick={() => changeLanguage("de")}
              />
            </div>
          )}
        </div>
        <Button
          title={i18n.t("becomeFlowerMePartner")}
          handleClick={() => {window.location.href = i18n.t("getLinkBecomingPartner");}}
          classname="drawer-transparent-button"
        />
        {!isConnected ? (
          <Button
            title="Connect Wallet"
            handleClick={handleClick}
            classname="hbtn"
          />
        ) : (
          <Button
            title={addr}
            handleClick={handleClick}
            classname="hbtn active"
          />
        )}
      </div>
    </div>
  );
};
