export const TOGGLE_MODAL: string = "TOGGLE_MODAL";

export const SET_DATA: string = "SET_DATA";

export const SET_CODE: string = "SET_CODE";

export const TOGGLE_DRAWER: string = "TOGGLE_DRAWER";
export const SET_LANG: string = "SET_LANG";

export const SET_STATE: string = "SET_STATE";
