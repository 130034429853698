import { configureStore } from "@reduxjs/toolkit";

import modalReducer from "./modalReducer";
import dataReducer from "./dataReducer";
import codeReducer from "./codeReducer";
import drawerReducer from "./drawerReducer";
import langReducer from "./langReducer";
import stateReducer from "./stateReducer";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    data: dataReducer,
    code: codeReducer,
    drawer: drawerReducer,
    lang: langReducer,
    state: stateReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
