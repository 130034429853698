import React, { useEffect, useState } from "react";
import { Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation} from "react-i18next";
import { toggleModal, setData } from "../../store/actionCreators";
import { EstonianTrees } from "../../type";
import sendRequest from "../../services";
import { AxiosResponse } from "axios";


export const EstoniaModal = () => {
  const [estonianData, setEstonianData] = useState<EstonianTrees | null>(null);
    const [error, setError] = useState<string | null>(null);

    const dispatch = useDispatch();
  
    const { t } = useTranslation();
  
    const dataSelector = useSelector((state: any) => state.data);
  
    const sharingSlug = dataSelector.shared_slug;
  
    const handleEstoniaData = (res: AxiosResponse) => {
      try {
        const data = res.data as EstonianTrees;
        setEstonianData(data);
        setError(null);
      } catch (error) {
        console.error('Error setting data:', error);
        setError("Error setting history data");
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          await sendRequest(
            "get",
            `/api/v1/main/estonia/${sharingSlug}`,
            undefined,
            handleEstoniaData,
            undefined,
            undefined,
          );
        } catch (error) {
          console.error('Error fetching history data:', error);
          setError("Error fetching history data");
        }
      };
  
      if (window.location.pathname === `/compensation/${sharingSlug}`) {
        fetchData();
      }
    }, [sharingSlug]);
  
    const handleClose = () => {
      dispatch(toggleModal("estonia"));
    };
  
    return (
      <Modal
        type="estonia"
        title={t('estonianProjectOverview')}
        helper={t("estoniaInfo")}
        handleClose={handleClose}
        classname="projectInfo"
      >
        {estonianData ? (
          <div className="project">
            <h2 className="project-header"> {t("EstoniaOwnOverview")} </h2>
            <h3>
              {t("NumberTreesOverview")} {estonianData.number_trees !== null
                ? estonianData.number_trees
                : "0"}
            </h3>
            <h3>
              {t("LastPlantingDate")} {estonianData.last_claimed !== null
                ? estonianData.last_claimed
                : "Never"}
            </h3>
            <h3>
              {t("PatronsNameEstonia")} {estonianData.name !== null
                ? estonianData.name
                : ""}
            </h3>
            <p className="project-header"> {t("estonianAchivable")}</p>
            <h3>
              {t("PlantingDate")} {estonianData.planting_date !== null
                ? estonianData.planting_date
                : "Currently in negotiation"}
            </h3>
          </div>
        ) : (
          <p>Loading... Please refresh the page...</p>
        )}
        {error && <p>{error}</p>}
      </Modal>
    );
};
