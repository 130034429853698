import { DrawerState, DrawerAction } from "../type";
import * as actionTypes from "./actionTypes";

const initialState: DrawerState = {
  isOpen: false,
};

const drawerReducer = (
  state: DrawerState = initialState,
  action: DrawerAction,
): DrawerState => {
  switch (action.type) {
    case actionTypes.TOGGLE_DRAWER:
      return {
        ...state,
        // code: action.codeAction?.code ?? "",
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
};

export default drawerReducer;
