import { DataState, DataAction } from "../type";
import * as actionTypes from "./actionTypes";

const initialState: DataState = {
  absorted_co: 0,
  currently_protected: 0,
  last_comp_tracked: "",
  protector: "",
  stored_co: 0,
  email: "",
  shared_slug: "",
  claiming_code: "",
};

const dataReducer = (
  state: DataState = initialState,
  action: DataAction,
): DataState => {
  switch (action.type) {
    case actionTypes.SET_DATA:
      return {
        ...state,
        absorted_co: action.data!.absorted_co,
        currently_protected: action.data!.currently_protected,
        last_comp_tracked: action.data!.last_comp_tracked,
        protector: action.data!.protector,
        stored_co: action.data!.stored_co,
        email: action.data!.email,
        shared_slug: action.data!.shared_slug,
        claiming_code: action.data!.claiming_code,
      };
    default:
      return state;
  }
};

export default dataReducer;
