import React from "react";

interface ButtonType {
  title: string;
  handleClick: () => void;
  classname?: string;
}

export const Button = ({ title, handleClick, classname }: ButtonType) => {
  return (
    <>
      <button className={`button ${classname}`} onClick={handleClick}>
        {title}
      </button>
    </>
  );
};
