import {
  ModalAction,
  DataAction,
  DataState,
  CodeAction,
  ICode,
  DrawerAction,
  ILang,
  LangAction,
} from "../type";
import * as actionTypes from "./actionTypes";

export function toggleModal(type: any): ModalAction {
  return {
    type: actionTypes.TOGGLE_MODAL,
    modalType: type,
  };
}

export function setData(data: DataState): DataAction {
  return {
    type: actionTypes.SET_DATA,
    data,
  };
}

export function setCode(codeAction: ICode): CodeAction {
  return {
    type: actionTypes.SET_CODE,
    codeAction,
  };
}

export function toggleDrawer(): DrawerAction {
  return {
    type: actionTypes.TOGGLE_DRAWER,
  };
}

export function setLang(lang: ILang): LangAction {
  return {
    type: actionTypes.SET_LANG,
    lang,
  };
}

export function setState(state: any): any {
  return {
    type: actionTypes.SET_STATE,
    state,
  };
}
