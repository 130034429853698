import { LangState, LangAction } from "../type";
import * as actionTypes from "./actionTypes";

const initialState: LangState = {
  language: "en",
};

const langReducer = (
  state: LangState = initialState,
  action: LangAction,
): LangState => {
  switch (action.type) {
    case actionTypes.SET_LANG:
      return {
        language: action.lang!.language,
      };
    default:
      return state;
  }
};

export default langReducer;
