import React, { useEffect, useState } from "react";

import { Button, Input } from "../../components";

import { useDispatch, useSelector } from "react-redux";

import {
  toggleModal,
  setData,
  setCode,
  setState,
} from "../../store/actionCreators";

import quantify_icon from "../../img/quantify_icon.svg";
import compare_icon from "../../img/compare_icon.svg";
import compensate_icon from "../../img/compensate_icon.svg";
import share_icon from "../../img/share_icon.svg";

import sendRequest from "../../services";
import { IData } from "../../type";
import { AxiosResponse } from "axios";

import { useTranslation, Trans } from "react-i18next";

export const CompensationDashboard = ({ setSharedSlug }: any) => {
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [successSubscribed, SetSuccessSubscribed] = useState(false);
  const [claimed_email, setClaimedEmail] = useState("");
  const currLang = useSelector((state: any) => state.lang);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const modalSelector = useSelector((state: any) => state.modal);
  const codeSelector = useSelector((state: any) => state.code);

  const handleEmailModal = () => {
    dispatch(toggleModal("email"));
  };

  const handleChangePrivacy = () => {
    setPrivacy(!privacy);
  };

  const handleChangeCode = (e: any) => {
    dispatch(setCode({ code: e.target.value }));
  };

  const handleChangeSurname = (e: any) => {
    setSurname(e.target.value);
  };

  const handleChangeLastname = (e: any) => {
    setLastname(e.target.value);
  };

  const handleShowError = () => {
    dispatch(toggleModal("error"));
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleShowSuccess = () => {
    dispatch(toggleModal("success"));
  };

  const handleChangeEmail = (e: any) => {
    setClaimedEmail(e.target.value);
  };

  const onSuccess = (res: AxiosResponse) => {
    const data: IData = {
      last_comp_tracked: res.data.last_comp_tracked,
      stored_co: res.data?.stored_co,
      absorted_co: res.data?.absorted_co,
      protector: res.data?.protector,
      currently_protected: res.data?.currently_protected,
      email: res.data?.email,
      shared_slug: res.data?.shared_slug,
      claiming_code: res.data?.claiming_code,
    };
    dispatch(setData(data));
    dispatch(setCode({ code: res.data?.claiming_code }));
    setSharedSlug(res.data?.shared_slug);
    if (res.data?.modal) {
      dispatch(toggleModal("flower"));
    } else {
      handleShowSuccess();
      setTimeout(() => {
        dispatch(setState({ state: true }));
        handleShowSuccess();
      }, 2000);
    }
  };

  const handleSendCode = () => {
    if (privacy) {
      const data: any = {
        claiming_code: codeSelector.code,
        lang: currLang.language,
      };

      if (claimed_email !== "" && claimed_email) {
        data.claimed_email = claimed_email;
      }

      sendRequest(
        "POST",
        "/api/v1/main/claim",
        data,
        onSuccess,
        handleShowError,
        undefined,
      );
    }
  };

  // useEffect(() => {
  //   if (modalSelector.success) {
  //     const timer = setTimeout(() => {
  //       // setState((prev: any) => !prev);
  //       handleShowSuccess();
  //     }, 2000);

  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }
  // }, [modalSelector]);

  const handleSendForm = () => {
    SetSuccessSubscribed(false);
    sendRequest(
      "POST",
      "/api/v1/main/form",
      {
        surname: surname,
        lastname: lastname,
        email_address: claimed_email,
        lang: currLang.language,
      },
      onFormSuccess,
      onFormError,
      undefined,
    );
  };

  const onFormSuccess = (res: AxiosResponse) => {
    SetSuccessSubscribed(true);
    setSurname("");
    setLastname("");
    setClaimedEmail("");
  };

  const onFormError = () => {
    console.log("something goes wrong...");
  };

  return (
    <div className="compensation-container">
      <form
        className="compensation-container-block"
        onSubmit={handleFormSubmit}
      >
        <h1 className="compensation-container-block-header">
          {/* You want to claim your contribution or link it to your account? */}
          {t("want")}
        </h1>
        <p className="compensation-container-block-helper">
          {/* Enter the code below. You can also register your email and start
          collecting contributions! */}
          {t("enter")}
        </p>
        
        <div className="compensation-container-block-inputblock">
          <div className="compensation-container-block-inputblock-element">
            <label htmlFor="code">{t("flowermeContributionCode")}</label>
            <Input
              placeholder={t("flowermeContributionCode")}
              name="code"
              value={codeSelector.code}
              onChange={handleChangeCode}
            />
          </div>
          <div className="compensation-container-block-inputblock-element">
            <label htmlFor="email">
              {/* E-mail */}
              {t("email")}
            </label>
            <Input
              placeholder={t("enterEmail")}
              name="email"
              value={claimed_email}
              onChange={handleChangeEmail}
            />
          </div>
        </div>
        <div className="compensation-container-block-privacy">
          <input
            type="checkbox"
            id="privacy"
            required
            checked={privacy}
            onChange={handleChangePrivacy}
          />
          <label htmlFor="privacy">
            {/* {t("acceptPrivacyPolicy")} */}
            <Trans i18nKey="acceptPrivacyPolicy">
              Accepting
              <a href={t("getPrivacyPolicyFooterLink")} target="_blank">
                privacy policy
              </a>
            </Trans> 
          </label>
        </div>
        <div className="compensation-container-block-privacy">
          <input type="checkbox" id="link" />
          <label htmlFor="link">
            {/* I want to link my email address to my tracking code */}
            {t("linkEmail")}
            {/* <Trans i18nKey="linkEmail" */}
          </label>
        </div>
        <div className="compensation-container-block-button">
          <Button title={t("redeemFlowerMe")} handleClick={handleSendCode} />
        </div>
        <div className="compensation-container-block-already">
          <p>
            {/* <Trans i18nKey={}> */}
            <Trans i18nKey="loginVerificationModal">
              You already have an account?
              <a onClick={handleEmailModal}> 
                Click here 
              </a>
              {/* </Trans> */}
            </Trans>
          </p>
        </div>
      </form>
      <div className="compensation-container-block">
        <h1 className="compensation-container-block-header">
          {t("howItWorksHeader")}
        </h1>
        <div className="compensation-container-block-icon-text">
          <div className="icon-text-container clearfix">
            <div className="icon">
              <img src={quantify_icon} alt="quantify_icon" />
            </div>
            <div className="text">
              <h2 className="headerText" dangerouslySetInnerHTML={{ __html: t('quantifyExplainer') }} />
            </div>
          </div>
        </div>
        <div className="compensation-container-block-icon-text">
          <div className="icon-text-container clearfix">
            <div className="icon">
              <img src={compare_icon} alt="compare_icon" />
            </div>
            <div className="text">
              <h2 className="headerText" dangerouslySetInnerHTML={{ __html: t('shopRedirectExplainer') }} />
            </div>
          </div>
        </div>
        <div className="compensation-container-block-icon-text">
          <div className="icon-text-container clearfix">
            <div className="icon">
              <img src={compensate_icon} alt="compensate_icon" />
            </div>
            <div className="text">
              <h2 className="headerText" dangerouslySetInnerHTML={{ __html: t('compensateYourCode') }} />
            </div>
          </div>
        </div>
        <div className="compensation-container-block-icon-text">
          <div className="icon-text-container clearfix">
            <div className="icon">
              <img src={share_icon} alt="share_icon" />
            </div>
            <div className="text">
              <h2 className="headerText" dangerouslySetInnerHTML={{ __html: t('shareYourImpact') }} />
            </div>
          </div>
        </div>
      </div>
      <div className="compensation-container-block">
        <h1 className="compensation-container-block-header">
          {/* You are new to the FlowerMe Initiative? */}
          {t("newToFlowerMe")}
        </h1>
        <p className="compensation-container-block-helper" id="second-helper">
          {/* Get in touch with us to compensate your purchases today! */}
          {t("getInTouch")}
        </p>
        <div className="compensation-container-block-input">
          <Input
            placeholder={t("surname")}
            name="Surname"
            value={surname}
            onChange={handleChangeSurname}
          />
          <Input
            placeholder={t("lastname")}
            name="lastname"
            value={lastname}
            onChange={handleChangeLastname}
          />
          <Input
            placeholder={t("email")}
            name="useremail"
            value={claimed_email}
            onChange={handleChangeEmail}
          />
        </div>
        <div className="compensation-container-block-privacy">
          <input type="checkbox" name="policy" id="policy" />
          <label htmlFor="policy">
            <Trans i18nKey="newsletterAgreement">
              I agree to receive the newsletter in compliance with the
              <a href={t("getPrivacyPolicyFooterLink")} target="_blank">
                Privacy Policy.
              </a>
            </Trans>
            {/* {t("newsletterAgreement")} */}
          </label>
        </div>
        {successSubscribed && <div className="__success_message">{t("SuccessMailchimp")}</div>}
        <div className="compensation-container-block-button" id="login-button">
          <Button title={t("sendEmailAddress")} handleClick={handleSendForm} />
        </div>
      </div>
    </div>
  );
};
